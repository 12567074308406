import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Nullable } from 'types/common';
import { Input, InputRef } from 'antd';
import { useMapsLibrary } from '@vis.gl/react-google-maps';

export const PlaceAutocomplete = ({
	onPlaceSelect,
}: {
	onPlaceSelect: (place: Nullable<google.maps.places.PlaceResult>) => void;
}) => {
	const { t: tCommon } = useTranslation('common');
	const [placeAutocomplete, setPlaceAutocomplete] = useState<Nullable<google.maps.places.Autocomplete>>(null);
	const inputRef = useRef<InputRef>(null);
	const places = useMapsLibrary('places');

	useEffect(() => {
		if (!places || !inputRef.current?.input) return;

		const options = {
			fields: ['geometry', 'name', 'formatted_address'],
		};
		setPlaceAutocomplete(new places.Autocomplete(inputRef.current.input, options));
	}, [places]);

	useEffect(() => {
		if (!placeAutocomplete) return;

		placeAutocomplete.addListener('place_changed', () => {
			onPlaceSelect(placeAutocomplete.getPlace());
		});
	}, [onPlaceSelect, placeAutocomplete]);

	return (
		<Input
			ref={inputRef}
			style={{
				top: 10,
				left: 15,
				width: 300,
				height: 40,
				padding: '0 12px',
			}}
			placeholder={tCommon('map_search_box.placeholder')}
		/>
	);
};
