import { AxiosRequestConfig } from 'axios';
import { THttpRequestConfig } from 'hooks';
import { IBulkUpdateGeneralSettingsConfig } from 'types/api';

export const GENERAL_SETTINGS_API = {
	list: (key?: string): THttpRequestConfig => ({
		url: 'operations/general-settings',
		method: 'GET',
		params: {
			key,
			area_ids: [], // Required in the context of CORE-4762. To be adapted when table filters on areas is added
		},
	}),

	bulkUpdate: (data: IBulkUpdateGeneralSettingsConfig): THttpRequestConfig => ({
		url: 'operations/general-settings/bulk-update',
		method: 'PATCH',
		params: {
			area_ids: [], // Required in the context of CORE-4762. To be adapted when table filters on areas is added
		},
		data,
	}),

	integrationSettings: (): AxiosRequestConfig => ({
		url: 'operations/integrations',
		method: 'GET',
	}),
};
