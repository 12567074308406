import { useEffect, useState } from 'react';
import { GENERAL_SETTINGS_API } from 'configs/api';
import { EGeneralSettingsKeys, IGeneralSettingsItem } from 'types/api';
import { IListResponse, Nullable } from 'types/common';
import { useNewHttpClient } from './useHttpClient';

interface IGeneralSettingsController {
	isLoading: boolean;
	generalSettingsList: Nullable<Record<string, IGeneralSettingsItem>>;
	getSettingValue: (key: EGeneralSettingsKeys) => Nullable<string>;
}

const useGeneralSettings = (key?: string): IGeneralSettingsController => {
	const [data, setData] = useState<Nullable<Record<string, IGeneralSettingsItem>>>(null);

	const fetchHttpClient = useNewHttpClient<IListResponse<IGeneralSettingsItem>>();

	const getSettingValue = (key: EGeneralSettingsKeys): Nullable<string> => {
		return (data && data[key]?.value) ?? null;
	};

	// ! effects
	useEffect(() => {
		fetchHttpClient.request({
			requestConfig: GENERAL_SETTINGS_API.list(key),
			successCallback: (response) => {
				const transformedData = response.data.reduce<Record<string, IGeneralSettingsItem>>((acc, item) => {
					acc[item.key] = item;
					return acc;
				}, {});

				setData(transformedData);
			},
		});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		isLoading: fetchHttpClient.isLoading,
		generalSettingsList: data,

		getSettingValue,
	};
};

export { useGeneralSettings };
